import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Footer from './Footer';
import DarkBackground from '../../assets/image/StatueOfLiberty.jpg';

const Welcome = () => {
  const isNonMediumScreens = useMediaQuery('(min-width: 1200px)');
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: '38vh',
            objectFit: 'cover',
          }}
          alt="Statue of Liberty Background Picture."
          src={DarkBackground}
        />
        <Box
          sx={{
            transform: 'translateY(-120%)',
            color: 'white',
            padding: '1rem',
          }}
        >
          <Typography 
            variant="h1" 
            align="center" 
            fontSize='4em'
            color={theme.palette.primary.main}
          >
            Doing what it takes!
          </Typography>
          <hr />
          <Typography 
            variant="subtitle1" 
            align="center" 
            fontSize='1.7em'
            color={theme.palette.primary.main}
          >
            Direct Campaign delivers cutting edge political technology that will take your campaign to new heights.
          </Typography>
        </Box>
        <Box 
          m="1.5rem 2.5rem"
          sx={{
            transform: 'translateY(-7%)',
          }}
        >
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            gridAutoRows="220px"
            gap="20px"
            sx={{
              '& > div': { gridColumn: isNonMediumScreens ? undefined : 'span 3' },
            }}
          >
            <Box>
              <Typography
                variant="h1"
                color={theme.palette.secondary.main}
                fontWeight="bold"
                sx={{ mb: '20px' }}
                align="center"
              >
                Who We Are
              </Typography>
              <Typography variant="h4" align="center">
                Direct Campaign is the leader in developing the newest political technology.
              </Typography>
            </Box>
            <Box />
            <Box>
              <Typography
                variant="h1"
                color={theme.palette.secondary.main}
                fontWeight="bold"
                sx={{ mb: '20px' }}
                align="center"
              >
                What We Do
              </Typography>
              <Typography variant="h4" align="center">
                We provide candidates and organizations across the country with the best voter contact applications.
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h1"
                color={theme.palette.secondary.main}
                fontWeight="bold"
                sx={{ mb: '20px' }}
                align="center"
              >
                Data
              </Typography>
              <Typography variant="h4" align="center">
                Giving you the latest information on your campaign.
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h1"
                color={theme.palette.secondary.main}
                fontWeight="bold"
                sx={{ mb: '20px' }}
                align="center"
              >
                Outreach
              </Typography>
              <Typography variant="h4" align="center">
                Providing the tools you need to contact your supporters.
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h1"
                color={theme.palette.secondary.main}
                fontWeight="bold"
                sx={{ mb: '20px' }}
                align="center"
              >
                Technology
              </Typography>
              <Typography variant="h4" align="center">
                The latest tech to help your campaign run the way it should.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Welcome;
