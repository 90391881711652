import React from 'react';
import { Box, Typography, useMediaQuery, useTheme, Link } from '@mui/material';
import DarkBackground from '../../assets/image/StatueOfLiberty.jpg';
import Footer from './Footer';

const AboutUs: React.FC = () => {
  const isNonMediumScreens = useMediaQuery('(min-width: 1200px)');
  const theme = useTheme();

  const marginSize = 1;

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: '38vh',
            objectFit: 'cover',
          }}
          alt="Statue of Liberty Background Picture."
          src={DarkBackground}
        />
        <Box
          sx={{
            transform: 'translateY(-120%)',
            color: 'white',
            padding: '1rem',
          }}
        >
          <Typography 
            variant="h1" 
            align="center" 
            fontSize='4em'
            color={theme.palette.primary.main}
          >
            Who we are
          </Typography>
          <hr />
          <Typography 
            variant="subtitle1" 
            align="center" 
            fontSize='1.7em'
            color={theme.palette.primary.main}
          >
            Meet the team behind the future of campaigns.
          </Typography>
        </Box>
        <Box 
          m="1.5rem 2.5rem" 
          margin={marginSize}
          sx={{
            transform: 'translateY(-12%)',
          }}
        >
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridAutoRows="220px"
            gap="20px"
            sx={{
              '& > div': { gridColumn: isNonMediumScreens ? undefined : 'span 3' },
            }}
          >
            <Box display="flex">
              <Box>
                <Typography variant="h2" align="center" color={theme.palette.secondary.main}>
                  Will Seykora
                </Typography>
                <Typography variant="subtitle1">
                  Will Seykora has been working in political field operations for over 5 years, winning multiples races
                  and awards along the way. Working in multiple nationally targeted races, Will has seen first hand the
                  big voter contact applications of today and has dedicated over 3 years to conceptualizing and creating
                  the canvassing app of the modern day.
                </Typography>
                <Typography align="center" margin={marginSize}>
                  <Link href="mailto:will@directcampaign.org" color="inherit" margin={marginSize}>
                    will@directcampaign.org
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Box>
                <Typography variant="h2" align="center" color={theme.palette.secondary.main}>
                  Evan Tobin
                </Typography>
                <Typography variant="subtitle1">
                  Evan Tobin is a passionate software engineer and aspiring cloud architect. Website management and big
                  data are his key specializations.
                </Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Box>
                <Typography variant="h2" align="center" color={theme.palette.secondary.main}>
                  Christian Tobin
                </Typography>
                <Typography variant="subtitle1">
                  Chrisitan Tobin is a senior cloud architect who always prioritizes security and reliability when
                  delivering software projects.
                </Typography>
              </Box>
            </Box>
            <Box display="flex">
              <Box>
                <Typography variant="h2" align="center" color={theme.palette.secondary.main}>
                  Brennan Goins
                </Typography>
                <Typography variant="subtitle1">
                  Brennan Goins began working on Direct Campaign from its conception, and during the last 3+ years he
                  has worked on software implementations including our web applications and backend technology, while
                  finishing a degree in Computer Science.
                </Typography>
                <Typography align="center" margin={marginSize}>
                  <Link
                    href="https://www.linkedin.com/in/brennan-goins/"
                    color="inherit"
                    target="_blank"
                    rel="noreferrer"
                    margin={marginSize}
                  >
                    LinkedIn
                  </Link>
                  <Link
                    href="https://brennang01.github.io/"
                    color="inherit"
                    target="_blank"
                    rel="noreferrer"
                    margin={marginSize}
                  >
                    Portfolio
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default AboutUs;
