import React from 'react';

const Settings: React.FC = () => {
  return (
    <div>
      <h1>This is the settings page.</h1>
    </div>
  );
};

export default Settings;
